<template>
  <div class="rank">
    <page-title />
    <div class="rank-box">
      <div class="rank-box-main">
        <div class="rank-title">地区排行榜</div>
        <!-- <van-tabs
          v-model="activeTab"
          type="card"
          color="#010E4F"
          background="transparent"
          @change="onChange"
        >
          <van-tab name="province" title="全省个人榜" />
          <van-tab name="area-user" title="地区个人榜" />
          <van-tab name="area-score" title="地区积分榜" /> 
        </van-tabs>-->
        <div class="rank-content">
          <table v-if="['province', 'area-user'].includes(activeTab)">
            <thead>
              <tr>
                <th width="10%">名次</th>
                <th width="20%">头像</th>
                <th width="35%" class="left-cell">微信ID</th>
                <th width="25%" class="left-cell">段位</th>
                <th width="10%" class="left-cell">积分</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rank, index) in rankList"
                :key="rank.userId"
                :class="{ 'is-self': rank.userId === user.openid }"
              >
                <td class="index-cell">{{ index + 1 }}</td>
                <td>
                  <img v-lazy="rank.avatar" class="avatar" alt="avatar" />
                </td>
                <td class="left-cell">{{ rank.nickName }}</td>
                <td class="left-cell">{{ rank.levelName }}</td>
                <td class="left-cell">{{ rank.scores }}</td>
              </tr>
            </tbody>
          </table>
          <table v-if="['area-score'].includes(activeTab)">
            <thead>
              <tr>
                <th width="25%">名次</th>
                <th width="55%" class="left-cell">区域</th>
                <th width="20%" class="left-cell">参与人次</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(rank, index) in rankList"
                :key="rank.userId"
                :class="{ 'is-self': rank.userId === user.openid }"
              >
                <td class="index-cell">{{ index + 1 }}</td>
                <td class="left-cell">{{ rank.areaName }}</td>
                <td class="left-cell">{{ rank.scores }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="lucky-rank" @click="$router.replace('/home')">
          <img :src="require('@/assets/icons/rule.png')" alt="" />
          <span>回到首页</span>
        </div>
      </div>

      <!-- <div class="rank-box-footer">
        <div class="rank-box-footer-left">
          <span>我的名次：{{ user.allRank }} </span>
          <span>积分：{{ user.allScore }}</span>
        </div>
        <div class="rank-box-footer-right">
          <img v-lazy="user.headImgUrl" alt="avatar" />
          <span>{{ user.nickname }}</span>
          <span class="user-type">
            &nbsp;
            <span>{{ user.levelName }}</span>
          </span>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'Rank',
  data() {
    return {
      activeTab: 'area-score',
      rankList: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  mounted() {
    this.init()
    this.$store.dispatch('GetInfo')
  },
  methods: {
    onChange() {
      this.init()
    },
    getRankList() {
      this.rankList = []
      if (this.activeTab === 'province') {
        return this.$http({
          url: '/xt-exam/etUser/allRank'
        })
      } else if (this.activeTab === 'area-user') {
        return this.$http({
          url: '/xt-exam/etUser/areaPersonRank'
        })
      } else {
        return this.$http({
          url: '/xt-exam/etUser/areaRank'
        })
      }
    },
    init() {
      this.getRankList().then(response => {
        console.log('------------', response)
        if (response.code == 200) {
          this.rankList = response.data
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.rank {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.rank-box {
  position: relative;
  flex: 1;
  background: #ffffff;
  height: 0;
}
.rank-box-main {
  position: absolute;
  top: 0;
  bottom: 3vh;
  right: 0;
  left: 0;
  overflow: auto;
  padding: 0 3.4vw;
  /deep/.van-tabs--card > .van-tabs__wrap {
    height: 2rem;
  }
  /deep/.van-tabs__nav--card {
    border: none;
    margin: 0 -6px;
    height: 2rem;

    .van-tab {
      border-radius: 18px;
      border: none !important;
      margin: 0 6px;
      background: #e5e6ed;
      font-size: 1.12rem;
      font-family: PingFang SC;
      font-weight: 500;
      line-height: 2rem;
    }
  }
  .rank-content {
    min-height: 56vh;
  }
  table {
    border-spacing: 0;
    margin-top: 3vh;
    width: 100%;
    thead {
      width: 22.86rem;
      height: 0.96rem;
      font-size: 1.04rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #010e4f;
    }
    tbody {
      tr {
        height: 3.21rem;
        font-size: 0.97rem;
        font-family: PingFang SC;
        font-weight: 800;
        color: #010e4f;
      }
      tr:nth-child(odd) {
        background: #f4f4f5;
      }
      .is-self {
        background: #e5dbbe !important;
        .avatar {
          border: 4px solid rgba(173, 136, 77, 0.44);
        }
      }
    }
    .index-cell {
      font-size: 0.97rem;
      font-family: PingFang SC;
      font-weight: 800;
      color: #a77a33;
    }
    .left-cell {
      text-align: left;
    }
    .right-cell {
      text-align: right;
    }
  }
  .avatar {
    width: 2.29rem;
    height: 2.29rem;
    background: #ffffff;
    border: 1px solid #010e4f;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.04);
    border-radius: 50%;
  }
}
.rank-box-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6vh;
  background: #ffffff;
  box-shadow: 0rem 0rem 1rem 0rem rgba(0, 0, 0, 0.19);
  font-size: 0.88rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #010e4f;
  display: flex;
  flex-direction: row;
  padding: 0 6.4vw;
  .rank-box-footer-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    > span {
      margin-right: 2vw;
    }
  }
  .rank-box-footer-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    width: 50%;
    > img {
      width: 1.64rem;
      height: 1.64rem;
      background: #ffffff;
      border: 0px solid #010e4f;
      box-shadow: 0rem 0rem 0rem 0rem rgba(0, 0, 0, 0.04);
      border-radius: 50%;
      margin-right: 2vw;
    }
  }
}
.rank-title {
  font-size: 1.49rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #010e4f;
  margin-top: 3vh;
  margin-bottom: 2vh;
}
.user-type {
  position: relative;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fefefe;
  background: transparent;
  width: 18vw;
  padding: 0.2rem;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    content: '';
    background: linear-gradient(0deg, #000a4e 0%, #0438ff 100%);
    // z-index: -1;
    transform: skewX(-10deg);
    border-radius: 8px;
  }
  > span {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
    padding: 0.2rem;
  }
}
.lucky-rank {
  font-size: 1.04rem;
  font-family: PingFang SC;
  font-weight: 800;
  color: #010e4f;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-top: 3vh;
  img {
    width: 1.29rem;
    height: 1.29rem;
    margin-right: 0.46rem;
  }
}
</style>
